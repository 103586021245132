.loginSignupHeader {
  font-weight: 600;
  font-size: 30px;
  color: black;
}
.loginForm {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  width: 30%;
  margin: 50px auto;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.field {
  width: 100%;
}
.field input {
  background-color: rgb(237, 237, 237);
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 5px;
  font-size: 15px;
}
.field input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
  background-color: white;
}
.field button {
  width: 100%;
  margin-top: 20px;
  background: white;
  border-radius: 10px;
  color: black;
  font-size: 20px;
  padding: 9px;
  border: 2px solid black;
  outline: none;
}
.field button:hover {
  cursor: pointer;
  background-color: var(--primaryColor);
}
.field button:disabled {
  cursor: not-allowed;
}
