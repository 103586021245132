.settings {
  display: block;
  flex-direction: column;
  background: #fff;
  padding: 30px;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 50px;
  min-width: 300px;
  width: 40%;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.settings .imgContainer {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.settings .imgContainer img {
  width: 100px;
  height: 100px;

  border-radius: 50%;
  border: 1px solid rgb(142, 142, 142);
}
.settings .btnGrp {
  display: flex;
  flex-direction: column;
}
.settings button {
  padding: 10px;
  width: fit-content;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #fff;
}
.settings button:hover {
  background-color: var(--primaryColor);
  cursor: pointer;
}
.settings button:focus {
  outline: none;
}
.settings .editBtn {
  box-sizing: border-box;
}
.settings .saveBtn {
  color: black;
  border: 2 px solid black;
  padding: 10px 20px;
}
.settings .goBack {
  color: var(--primaryColor);
  margin-top: 7px;
  font-size: 13px;
}
.settings .goBack:hover {
  cursor: pointer;
  text-decoration: underline;
}
.settings .field {
  margin-bottom: 10px;
}
.settings .field .fieldLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #9e9e9e;
  padding: 5px 0;
}
.settings .field input {
  margin-top: 6px;
  width: 100%;
  height: 35px;
  font-size: 16px;
  padding-left: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
