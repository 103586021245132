.chatbox {
  position: fixed;
  bottom: 2px;
  right: 2px;
  width: 250px;
  height: 300px;
  border: 1px solid black;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messages {
  list-style-type: none;
  box-sizing: border-box;
  padding: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  /* border: 1px solid black; */
  height: 70%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow-y: auto;
  background-color: white;
}

.message {
  display: flex;
  margin: 10px;
}
.message span {
  font-size: smaller;
  box-sizing: border-box;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  word-wrap: break-word;
  max-width: 170px;
}
.incommingMessage {
  justify-content: flex-start;
}
.incommingMessage span {
  background-color: white;
}
.outgoingMessage {
  justify-content: flex-end;
}
.outgoingMessage span {
  background-color: #b4fcd9;
}

/*New Message*/
.newMessage {
  border-top: 0.2px solid rgb(237, 237, 237);
  padding-top: 10px;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  /* margin: 10px; */
  width: 100%;
  align-items: center;
}
.newMessage button {
  border: 1px solid black;
  background-color: white;
  width: 50px;
  height: 30px;

  text-align: center;
  border-radius: 5px;
  outline: none;
}

.newMessage button:hover {
  cursor: pointer;
  outline: none;
  outline: 2px solid var(--primaryColor);
  outline-offset: 2px;
}
.newMessage button:active {
  transform: translateY(1px);
  outline: none;
  background-color: white;
}
.newMessage input {
  width: 68%;
  height: 30px;
  border: none;
  box-sizing: border-box;
  border-radius: 25px;
  padding-left: 10px;
  background-color: rgb(237, 237, 237);
}

.newMessage input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
  background-color: white;
}

.chatHeader {
  background-color: var(--primaryColor);
  box-sizing: border-box;
  padding: 5px;
  height: 13%;
  width: 100%;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
/*Chat Icon*/
.chatIcon {
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border: 0.3px solid black;
  cursor: pointer;
}

.chatIcon img {
  width: 25px;
  height: 25px;
}
