.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dropdownIcon {
  width: 25px;
  height: 25px;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 28px;
  right: 8px;
  border-radius: 6px;
  z-index: 1px;
  text-align: center;
  padding: 5px;
}
