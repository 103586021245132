body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* LOADER */
.app-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #d6d6d6;
  border-radius: 50%;
  border-top-color: var(--primaryColor);
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  margin-right: 10px;
  position: absolute;
  top: 45%;
  left: 50%;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*Scroll bar*/
::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(237, 237, 237);
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 1px 5px rgb(194, 194, 194);
}
::-webkit-scrollbar-button {
  /* visibility: hidden; */
  height: 0.01px;
  background-color: red;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(174, 174, 174);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(99, 99, 99);
}

:root {
  --primaryColor: #ffe500;
}
