.nav {
  width: 100%;
  display: flex;
  height: 60px;
  /* border-bottom: 2px solid var(--primaryColor); */
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 5px rgb(230, 230, 230);
  background-color: white;
}
.leftDiv {
  margin: 5px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightDiv {
  margin: 5px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.navLinks button {
  border: 1px solid black;
  background-color: white;
  width: 70px;
  height: 30px;
  margin: 5px;
  text-align: center;
  border-radius: 5px;
  outline: none;
}
.navLinks button:hover {
  cursor: pointer;
  outline: none;
  outline: 2px solid var(--primaryColor);
  outline-offset: 2px;
}
.navLinks button:active {
  transform: translateY(1px);
  outline: none;
  background-color: white;
}

.searchContainer {
  margin: 10px;
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}
.searchIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 13px;
}
.searchContainer > input {
  width: inherit;
  height: 30px;
  border: none;
  border-radius: 25px;
  padding-left: 35px;
  background-color: rgb(237, 237, 237);
}

.searchContainer > input:focus {
  outline: none;
  border: 2px solid var(--primaryColor);
  background-color: white;
}
.searchResults {
  background: #fff;
  flex-grow: 1;
  border-radius: 0 0 8px 8px;
  position: absolute;
  width: 83%;
  top: 35px;
  left: 23px;
  border: 1px solid #e0e0e0;
  z-index: 100;
  max-height: 250px;
  height: auto;
  /* overflow: scroll; */
  /* display: none; */
  overflow-y: auto;
}
.searchResults ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.searchResults ul a {
  text-decoration: none;
  color: black;
}
.searchResultsRow {
  display: flex;
  align-items: center;
  padding: 9px 14px;
}
.searchResultsRow:hover {
  background-color: #eceffa;
  cursor: pointer;
}
.link {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.searchResultsRow img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgb(142, 142, 142);
}
.user {
  display: flex;
  align-items: center;
}
.userDp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgb(142, 142, 142);
  margin-right: 10px;
}
